/**
  @file statuses correspond to the "status" field on our session endpoints, and result in different top-level screens rendering.
  Checkout path statuses partially documented here https://product.hubteam.com/docs/sales-payments-docs/payments_sessions.html
 */

export const OPEN = 'OPEN';
export const CANCELED = 'CANCELED';
export const EXPIRED = 'EXPIRED';
export const PROCESSING = 'PROCESSING';
export const SUCCEEDED = 'SUCCEEDED';
export const FAILED = 'FAILED';
export const PENDING = 'PENDING';

/**
 * @note statuses set by the FE
 * SOURCE_ID_PAID: set in FE as a response to BE checkout submission error. Indicates that a payment is already being processed for a session.
 * SUBMITTED: set in FE to handle the brief period between a checkout submission and the status being updated from BE after the payment is processed.
 */
export const SUBMITTED = 'SUBMITTED';
export const SOURCE_ID_PAID = 'SOURCE_ID_PAID';