import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
// @ts-expect-error Untyped module
import Notifications from 'ReduxMessenger/reducers/Notifications';
import buyerOverrides from '../state/BuyerOverrides/reducer';
import discountCode from '../state/DiscountCode/discountCodeSlice';
import errorMessages from '../state/errorMessagesSlice';
import lineItemBlurs from '../state/lineItemBlursSlice';
import calculateTax from '../state/calculateTaxSlice';
const reducer = combineReducers({
  notifications: Notifications,
  buyerOverrides,
  discountCode,
  errorMessages,
  lineItemBlurs,
  calculateTax
});
export const createStore = ({
  preloadedState
} = {}) => configureStore({
  devTools: true,
  reducer,
  preloadedState
});
export default reducer;