import { createSlice, createSelector } from '@reduxjs/toolkit';
const initialState = {};
export const getLineItemBlursStore = state => state.lineItemBlurs;
export const getLineItemBlurs = createSelector([getLineItemBlursStore], lineItemBlurs => lineItemBlurs);
export const getLineItemBlurByLineItemId = createSelector([getLineItemBlursStore, (state, lineItemId) => lineItemId], (lineItemBlurs, lineItemId) => lineItemBlurs[lineItemId]);
const lineItemBlursSlice = createSlice({
  name: 'lineItemBlurs',
  initialState,
  reducers: {
    setLineItemBlur: (state, action) => {
      state[action.payload.lineItemId] = action.payload.isBlurred;
    }
  }
});
export const {
  setLineItemBlur
} = lineItemBlursSlice.actions;
export default lineItemBlursSlice.reducer;