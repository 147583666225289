import { createSlice, createSelector } from '@reduxjs/toolkit';
const initialState = {
  messages: {
    // ordered by the order in which they appear on the checkout form
    emptyOrInvalidEmailAddress: null,
    emptyFirstName: null,
    emptyLastName: null,
    emptyAddressLine1: null,
    emptyCity: null,
    emptyCityTownVillage: null,
    emptyState: null,
    emptyStateProvinceRegion: null,
    emptyPostalCode: null,
    emptyAccountHolderName: null,
    emptyNameOnCard: null,
    merchantTermsNotAcknowledged: null,
    collectPMOFNotAcknowledged: null
  },
  totalsMessages: {},
  customFormFieldMessages: {},
  showAllErrors: false // set to true when confirm payment button is clicked
};
const getErrorMessageStore = state => state.errorMessages;
export const getErrorMessages = createSelector([getErrorMessageStore], errorMessages => errorMessages.messages);
export const getTotalsErrorMessages = createSelector([getErrorMessageStore], errorMessages => errorMessages.totalsMessages);
export const getCustomFormFieldErrorMessages = createSelector([getErrorMessageStore], errorMessages => errorMessages.customFormFieldMessages);
export const getShowAllErrors = createSelector([getErrorMessageStore], errorMessages => errorMessages.showAllErrors);
const errorMessagesSlice = createSlice({
  name: 'errorMessages',
  initialState,
  reducers: {
    setErrorMessages(state, action) {
      state.messages = Object.assign({}, state.messages, action.payload);
    },
    setTotalsErrorMessages(state, action) {
      state.totalsMessages = Object.assign({}, state.totalsMessages, action.payload);
    },
    setCustomFormFieldErrorMessages(state, action) {
      state.customFormFieldMessages = Object.assign({}, state.customFormFieldMessages, action.payload);
    },
    setShowAllErrors(state, action) {
      state.showAllErrors = action.payload;
    }
  }
});
export const {
  setErrorMessages,
  setTotalsErrorMessages,
  setCustomFormFieldErrorMessages,
  setShowAllErrors
} = errorMessagesSlice.actions;
export default errorMessagesSlice.reducer;