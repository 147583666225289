import { AMOUNT_REMAINING_LESS_THAN_MIN, PAYMENT_AMOUNT_LESS_THAN_MIN } from '../../constants/PaymentAmount';
function getPaymentAmountValidationError({
  minimumAmount,
  partialPaymentAmount,
  amountRemaining
}) {
  if (partialPaymentAmount !== undefined && minimumAmount !== undefined && partialPaymentAmount < minimumAmount) {
    return {
      validationError: PAYMENT_AMOUNT_LESS_THAN_MIN,
      messageProps: {
        message: 'payments.partialPayments.amountBelowMsg_jsx',
        value: minimumAmount
      }
    };
  }
  if (amountRemaining !== undefined && minimumAmount !== undefined && amountRemaining > 0 && amountRemaining < minimumAmount) {
    return {
      validationError: AMOUNT_REMAINING_LESS_THAN_MIN,
      messageProps: {
        message: 'payments.partialPayments.amountRemainingBelowMin_jsx',
        value: minimumAmount
      }
    };
  }
  return {
    validationError: null
  };
}
export default getPaymentAmountValidationError;