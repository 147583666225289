import { isOptional } from '../types/utils/typeguards';
/**
 * Sort line items per spec, and sort related arrays to keep them in sync.
 */
export const sortLineItemArrays = ({
  lineItems,
  selectionToggles,
  lineItemErrors,
  selectedItems
}) => {
  const sortedLineItemArrays = {
    required: {
      items: [],
      toggles: [],
      errors: [],
      selections: []
    },
    selected: {
      items: [],
      toggles: [],
      errors: [],
      selections: []
    },
    nonSelected: {
      items: [],
      toggles: [],
      errors: [],
      selections: []
    }
  };
  const {
    required,
    selected,
    nonSelected
  } = lineItems.reduce((acc, lineItem, i) => {
    const {
      items,
      toggles,
      errors,
      selections
    } = !isOptional(lineItem) ? acc.required : selectedItems[i] ? acc.selected : acc.nonSelected;
    items[items.length] = lineItem;
    toggles[toggles.length] = selectionToggles[i];
    errors[errors.length] = lineItemErrors[i];
    selections[selections.length] = selectedItems[i];
    return acc;
  }, sortedLineItemArrays);
  return ['items', 'toggles', 'errors', 'selections'].map(key => [...required[key], ...selected[key], ...nonSelected[key]
  // cast back to correct type
  ]);
};